import { Helmet } from 'react-helmet-async';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography, Box} from '@mui/material';
import moment from 'moment';
// redux
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getIndicadores, setIndicadores } from '../store/slices/indicadores';

// components
import {Logo, LogoPolarycs} from '../components/logo'
// sections
import {
  AppCurrentVisits,
  AppWidgetSummary,
} from '../sections/@dashboard/app';


// ----------------------------------------------------------------------

export default function DashboardAppPage() {

  const theme = useTheme();
  const dispatch = useDispatch();
  const [ time, setTime ] = useState(0);
  const [counter, setCounter] = useState(1);

  const { loadingIndicadores, indicadores } = useSelector((state) => state.indicadores);

  useEffect(() => {
    dispatch(getIndicadores());
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCounter((prevCounter) => prevCounter + 1);
      window.location.reload(false);
    }, 60000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  if(!loadingIndicadores) {
    return (
      <>
        <Helmet>
          <title>Bodega EcoCarga</title>
        </Helmet>

        <Container style={{maxWidth:'100%'}}>
          <Box sx={{ px: 1, py: 1, display: 'inline-flex', position: 'absolute', right: 50 }}>
            <Logo />
          </Box>
          <Box sx={{ px: 1, py: 1, display: 'inline-flex', position: 'absolute', bottom: 20, right:50 }}>
            <LogoPolarycs sx={{marginLeft:2}}/>
          </Box>
          <Typography variant="h3" sx={{ mb: 2 }} mt={-13}>
            Mercado Circular / EcoCarga / Indicadores Bodega / Actualizado {moment().format('HH:mm:ss')}
          </Typography>

          <Grid container spacing={3} sx={{ mb: 2}}>
            <Grid item xs={12} sm={6} md={2}/>
            <Grid item xs={12} sm={6} md={2}>
              <Typography variant="h4" align="center" >
                Totales Unidades
              </Typography>
            </Grid>
            {/* <Grid item xs={12} sm={6} md={1}>
              <Typography variant="h4" align="center">
                %
              </Typography>
            </Grid> */}
            <Grid item xs={12} sm={6} md={2}>
              <Typography variant="h4" align="center">
                Detergente
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <Typography variant="h4" align="center">
                Suavizante
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <Typography variant="h4" align="center">
                Lavalozas
              </Typography>
            </Grid>
            {/* <Grid item xs={12} sm={6} md={2}>
              <Typography variant="h4" align="center">
                Clorogel
              </Typography>
            </Grid> */}
          </Grid>
 
          <Grid container spacing={3} sx={{ mb: 2 }}>
            <Grid item xs={12} sm={6} md={2}>
              <Typography variant="h4">
                Litros Totales
              </Typography>
            </Grid>
            
            <Grid item xs={12} sm={6} md={2}>
              <AppWidgetSummary title="Total Graneles" total={indicadores.litrosTotales} color="warning" icon={'ant-design:android-filled'} />
            </Grid>
            {/* <Grid item xs={12} sm={6} md={1}/> */}
            <Grid item xs={12} sm={6} md={2}>
              <AppWidgetSummary title="Detergente" total={indicadores.categorias[0].litros} color="ecocargaDetergente" icon={'ant-design:android-filled'} />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <AppWidgetSummary title="Suavizante" total={indicadores.categorias[2].litros} color="ecocargaSuavizante" icon={'ant-design:apple-filled'} />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <AppWidgetSummary title="Lavalozas" total={indicadores.categorias[1].litros} color="ecocargaLavalozas" icon={'ant-design:windows-filled'} />
            </Grid>
            {/* <Grid item xs={12} sm={6} md={2}>
              <AppWidgetSummary title="Lavalozas" total={indicadores.categorias[3].litros} color="ecocargaClorogel" icon={'ant-design:windows-filled'} />
            </Grid> */}
          </Grid>



          <Grid container spacing={3} sx={{ mb: 2 }}>
            <Grid item xs={12} sm={6} md={2}>
              <Typography variant="h4">
                IBC Llenos <Typography variant="h5">( + 200 Lts )</Typography>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <AppWidgetSummary title="Total Llenos" color="warning" total={indicadores.contenedoresLlenos} icon={'ant-design:android-filled'} />
            </Grid>
            {/* <Grid item xs={12} sm={6} md={1}>
              <AppWidgetSummary title="% del Universo" total={indicadores.porcentajeContenedoresLlenos} color="warning" icon={'ant-design:bug-filled'} />
            </Grid>   */}
            <Grid item xs={12} sm={6} md={2}>
              <AppWidgetSummary title="Detergente" total={indicadores?.categorias[0]?.cantidadContenedoresLlenos} color="ecocargaDetergente" icon={'ant-design:android-filled'} />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <AppWidgetSummary title="Suavizante" total={indicadores?.categorias[2]?.cantidadContenedoresLlenos} color="ecocargaSuavizante" icon={'ant-design:apple-filled'} />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <AppWidgetSummary title="Lavalozas" total={indicadores?.categorias[1]?.cantidadContenedoresLlenos} color="ecocargaLavalozas" icon={'ant-design:windows-filled'} />
            </Grid>
            {/* <Grid item xs={12} sm={6} md={2}>
              <AppWidgetSummary title="Lavalozas" total={indicadores?.categorias[3]?.cantidadContenedoresLlenos} color="ecocargaClorogel" icon={'ant-design:windows-filled'} />
            </Grid> */}
          </Grid>


          <Grid container spacing={3} sx={{ mb: 2 }}>

            <Grid item xs={12} sm={6} md={2}>
              <Typography variant="h4">
                Tambores Llenos
                {/* IBC Vacios <Typography variant="h5">( - 200 Lts )</Typography> */}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <AppWidgetSummary title="Total Vacios" color="warning" total={indicadores.contenedoresVaciosEnBodega} icon={'ant-design:android-filled'} />
            </Grid>
            {/* <Grid item xs={12} sm={6} md={1}>
              <AppWidgetSummary title="% del Universo" total={indicadores.porcentajeContenedoresVacios} color="warning" icon={'ant-design:bug-filled'} />
            </Grid>   */}
            <Grid item xs={12} sm={6} md={2}>
              <AppWidgetSummary title="Detergente" total={indicadores.categorias[0].cantidadContenedoresVacios} color="ecocargaDetergente" icon={'ant-design:android-filled'} />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <AppWidgetSummary title="Suavizante" total={indicadores.categorias[2].cantidadContenedoresVacios} color="ecocargaSuavizante" icon={'ant-design:apple-filled'} />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <AppWidgetSummary title="Lavalozas" total={indicadores.categorias[1].cantidadContenedoresVacios} color="ecocargaLavalozas" icon={'ant-design:windows-filled'} />
            </Grid>
            {/* <Grid item xs={12} sm={6} md={2}>
              <AppWidgetSummary title="Lavalozas" total={indicadores.categorias[3].cantidadContenedoresVacios} color="ecocargaClorogel" icon={'ant-design:windows-filled'} />
            </Grid> */}
          </Grid>

   

          <Grid container spacing={3} sx={{ mb: 2 }}>
            <Grid item xs={12} sm={6} md={2}>
              <Typography variant="h4">
                IBC en Proveedor
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <AppWidgetSummary title="Total Vacios" color="warning" total={indicadores.totalContendoresKincell + indicadores.totalContenedoresDemaria} icon={'ant-design:android-filled'} />
            </Grid>
            {/* <Grid item xs={12} sm={6} md={1}>
              <AppWidgetSummary title="% del Universo" total={indicadores.porcentajeContenedoresKincell} color="warning" icon={'ant-design:bug-filled'} />
            </Grid>   */}
            <Grid item xs={12} sm={6} md={2}>
              <AppWidgetSummary title="Detergente" total={indicadores.categorias[0].enKincell} color="ecocargaDetergente" icon={'ant-design:android-filled'} />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <AppWidgetSummary title="Suavizante" total={indicadores.categorias[2].enKincell} color="ecocargaSuavizante" icon={'ant-design:apple-filled'} />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <AppWidgetSummary title="Lavalozas" total={indicadores.categorias[1].enDemaria} color="ecocargaLavalozas" icon={'ant-design:windows-filled'} />
            </Grid>
            {/* <Grid item xs={12} sm={6} md={2}>
              <AppWidgetSummary title="Lavalozas" total={indicadores.categorias[3].enDemaria} color="ecocargaClorogel" icon={'ant-design:windows-filled'} />
            </Grid> */}
          </Grid>



          {/* <Grid container spacing={3} sx={{ mb: 2 }}>
            <Grid item xs={12} sm={6} md={2}>
              <Typography variant="h4" mt={2}>
                Bidones Clorogel
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <AppWidgetSummary title="Litros Totales" total={indicadores.categorias[3].litros} color="ecocargaClorogel" icon={'ant-design:windows-filled'} />
            </Grid>
            <Grid item xs={12} sm={6} md={2}/>
            <Grid item xs={12} sm={6} md={2}>
              <AppWidgetSummary title="Bidones Universo" total={indicadores.categorias[3].totalBidones} color="ecocargaClorogel" icon={'ant-design:bug-filled'} />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <AppWidgetSummary title="Total Vacios" color="ecocargaClorogel" total={indicadores.categorias[3].cantidadContenedoresVacios} icon={'ant-design:android-filled'} />
            </Grid>

            <Grid item xs={12} sm={6} md={2}>
              <AppWidgetSummary title="Total Llenos" total={indicadores.categorias[3].cantidadContenedoresLlenos} color="ecocargaClorogel" icon={'ant-design:android-filled'} />
            </Grid>
            
            
          </Grid> */}

          

          <Grid container spacing={3} mt={1}>
            {/* <Grid item xs={12} md={6} lg={2}>
                <AppCurrentVisits
                  title="Litros en Bodega"
                  chartData={[
                    { label: 'Detergente', value: indicadores.categorias[0].litros },
                    { label: 'Suavizante', value: indicadores.categorias[2].litros },
                    { label: 'Lavalozas', value: indicadores.categorias[1].litros },
                    { label: 'Clorogel', value: indicadores.categorias[3].litros },
                  ]}
                  chartColors={[
                    theme.palette.ecocargaDetergente.main,
                    theme.palette.ecocargaSuavizante.main,
                    theme.palette.ecocargaLavalozas.main,
                    theme.palette.ecocargaClorogel.main,
                  ]}
                  total={
                    indicadores.categorias[0].litros +
                    indicadores.categorias[1].litros +
                    indicadores.categorias[2].litros +
                    indicadores.categorias[3].litros
                  }
                  muestraUnidades={false}
                />
            </Grid> */}
            <Grid item xs={12} md={6} lg={2}>
                <AppCurrentVisits
                  title="Lotes Existentes"
                  chartData={[
                    { label: 'Detergente', value: indicadores.categorias[0].lotesDistintos.length },
                    { label: 'Suavizante', value: indicadores.categorias[2].lotesDistintos.length },
                    { label: 'Lavalozas', value: indicadores.categorias[1].lotesDistintos.length },
                    { label: 'Clorogel', value: indicadores.categorias[3].lotesDistintos.length },
                  ]}
                  total={
                    indicadores.categorias[0].lotesDistintos.length +
                    indicadores.categorias[1].lotesDistintos.length +
                    indicadores.categorias[2].lotesDistintos.length +
                    indicadores.categorias[3].lotesDistintos.length 
                  }
                  chartColors={[
                    theme.palette.ecocargaDetergente.main,
                    theme.palette.ecocargaSuavizante.main,
                    theme.palette.ecocargaLavalozas.main,
                    theme.palette.ecocargaClorogel.main,
                  ]}
                  muestraUnidades={1}
                />
            </Grid>


            {/* <Grid item xs={12} md={6} lg={4}>
                <AppCurrentVisits
                  title="Estado de IBC"
                  chartData={[
                    { label: 'Llenos', value: indicadores.estadoContenedores.llenos },
                    { label: 'Vacios', value: indicadores.estadoContenedores.vacios },
                    { label: 'Proveedor', value: indicadores.estadoContenedores.proveedor },
                    { label: 'Reportados', value: indicadores.estadoContenedores.reportados },
                    { label: 'Transito', value: indicadores.estadoContenedores.transitoPOS },
                    { label: 'Recepcion Merma', value: indicadores.estadoContenedores.recepcionMerma },
                    { label: 'Recepcion Limpieza', value: indicadores.estadoContenedores.recepcionLimpiezaSanitizado },
                  ]}
                  chartColors={[
                    theme.palette.ecocargaDetergente.main,
                    theme.palette.ecocargaSuavizante.main,
                    theme.palette.ecocargaLavalozas.main,
                    theme.palette.ecocargaClorogel.main,
                  ]}
                  total={
                    indicadores.estadoContenedores.llenos +
                    indicadores.estadoContenedores.vacios +
                    indicadores.estadoContenedores.proveedor +
                    indicadores.estadoContenedores.reportados +
                    indicadores.estadoContenedores.transitoPOS +
                    indicadores.estadoContenedores.recepcionMerma +
                    indicadores.estadoContenedores.recepcionLimpiezaSanitizado
                  }
                  muestraUnidades={1}
                />
            </Grid> */}

            {/* <Grid item xs={12} md={6} lg={2}>
                <AppCurrentVisits
                  title="IBC reportados"
                  chartData={[
                    { label: 'Detergente', value: indicadores.categorias[0].cantidadReportados },
                    { label: 'Suavizante', value: indicadores.categorias[2].cantidadReportados },
                    { label: 'Lavalozas', value: indicadores.categorias[1].cantidadReportados },
                    { label: 'Clorogel', value: indicadores.categorias[3].cantidadReportados },
                  ]}
                  chartColors={[
                    theme.palette.ecocargaDetergente.main,
                    theme.palette.ecocargaSuavizante.main,
                    theme.palette.ecocargaLavalozas.main,
                    theme.palette.ecocargaClorogel.main,
                  ]}
                  muestraUnidades={1}
                />
            </Grid> */}

            {/* <Grid item xs={12} md={6} lg={2}>
              <AppWidgetSummary title="IBC para Reacondicionamiento" total={indicadores.ibcRecuperacion} color="error" prefix="de 24" icon={'ant-design:apple-filled'} showTitle={1} marginBottom={3}/>
              <AppWidgetSummary title="IBC dados de baja" total={indicadores.estadoContenedores.dadosDeBaja} color="primary" showTitle={1} />
            </Grid> */}
          </Grid>

        </Container>
      </>
    );
  }

  return (<>
  
    <Container style={{maxWidth:'100%'}}>
          <Box sx={{display: 'block', textAlign:'center'}}>
            <Logo />
          </Box>
          <Box sx={{display: 'block', textAlign:'center'}}>
            <Typography variant="h3" sx={{ mb: 2 }} mt={2}>
              Refrescando Indicadores...
            </Typography>
          </Box>
    </Container>
  
  </>)
}
