import { createSlice } from "@reduxjs/toolkit";
import { API_URL } from "../../constant/api.constants";

const initialState = {
  loadingIndicadores: true, 
  indicadores: {},
  disponibilidadEcoVendings: [],
  kpi: {},
  loadingLayout: true, 
  layout: [], 
  loadingCalendario: false, 
  calendario: [],
  calendarioSemAnterior: [],
  loadingCalendarioAnterior: false
};

export const indicadoresSlice = createSlice({
  name: "indicadores",
  initialState,
  reducers: {
    setLoadingIndicadores: (state, action) => {
      state.loadingIndicadores = action.payload;
    },
    setIndicadores: (state, action) => {
      state.indicadores = action.payload;
    },
    setDisponibilidadEcoVendings: (state, action) => {
      state.disponibilidadEcoVendings = action.payload;
    },
    setKPI: (state, action) => {
      state.kpi = action.payload;
    },
    setLoadingLayout: (state, action) => {
      state.loadingLayout = action.payload;
    },
    setLayout: (state, action) => {
      state.layout = action.payload;
    },
    setCalendario: (state, action) => {
      state.calendario = action.payload;
    },
    setCalendarioAnterior: (state, action) => {
      console.log('set anterior : ', action.payload)
      state.calendarioSemAnterior = action.payload;
    },
    setLoadingCalendario: (state, action) => {
      state.loadingCalendario = action.payload;
    },
    setLoadingCalendarioAnterior: (state, action) => {
      state.loadingCalendarioAnterior = action.payload;
    },
}});

export const { 
  setLoadingIndicadores, 
  setIndicadores,
  setDisponibilidadEcoVendings,
  setLoadingLayout,
  setLayout,
  setKPI,

  setCalendario,
  setLoadingCalendario,
  setCalendarioAnterior,
  setLoadingCalendarioAnterior
} = indicadoresSlice.actions;

export default indicadoresSlice.reducer;

export const getIndicadores = () => async (dispatch) => {
  try {

    dispatch(setLoadingIndicadores(true))
    const response = await fetch(
      `${API_URL}/contenedores/kpi/bodega`,
      {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          mcdotoken:
            "ExMzEyMzMudTk4VTk4V0Y3NkZjEyRlNEujEydWF3bjgxMnVWVleyJzd94V0Y3NkZjEy",
        },
      }
    );
    const json = await response.json();
    console.log(json)
    dispatch(setIndicadores(json));
    
  } catch (error) {
    console.log("Api indicadores : error");
    console.error(error);
  } finally {
    console.log("Api indicadores : ok");
    dispatch(setLoadingIndicadores(false))
  }
};

export const getLayout = () => async (dispatch) => {
  try {

    dispatch(setLoadingLayout(true))
    const response = await fetch(
      `${API_URL}/da-zonas/kpi/layout`,
      {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          mcdotoken:
            "ExMzEyMzMudTk4VTk4V0Y3NkZjEyRlNEujEydWF3bjgxMnVWVleyJzd94V0Y3NkZjEy",
        },
      }
    );
    const json = await response.json();
    console.log(json)
    dispatch(setLayout(json));
    
  } catch (error) {
    console.log("Api layout : error");
    console.error(error);
  } finally {
    console.log("Api layout : ok");
    dispatch(setLoadingLayout(false))
  }
};

export const getCalendarioNuevo = (fecha) => async (dispatch) => {
  try {

    dispatch(setLoadingCalendario(true))
    const response = await fetch(
      `${API_URL}/da-planificacions/calendario`,
      {
        method: "POST",
        body: JSON.stringify({
          'tipo' : 'calendario',
          'fecha' : fecha,
          'filtros' : {
            'planificacion' : 'calendarioTotal'
          }
        }),
        headers: {
          "Content-type": "application/json",
          mcdotoken: "ExMzEyMzMudTk4VTk4V0Y3NkZjEyRlNEujEydWF3bjgxMnVWVleyJzd94V0Y3NkZjEy",
        },
      }
    );
    const json = await response.json();
    console.log('calendarioNuevo : ', json )
    dispatch(setCalendario(json));
    
  } catch (error) {
    console.log("Api layout : error");
    console.error(error);
    dispatch(setLoadingCalendario(false))
  } finally {
    console.log("Calendario : ok");
    dispatch(setLoadingCalendario(false))
  }
};

export const getCalendarioNuevoAnterior = (fecha) => async (dispatch) => {
  try {

    dispatch(setLoadingCalendarioAnterior(true))
    console.log({
      'tipo' : 'calendario',
      'fecha' : fecha,
      'filtros' : {
        'planificacion' : 'calendarioTotal'
      }
    })
    const response = await fetch(
      `${API_URL}/da-planificacions/calendario`,
      {
        method: "POST",
        body: JSON.stringify({
          'tipo' : 'calendario',
          'fecha' : fecha,
          'filtros' : {
            'planificacion' : 'calendarioTotal'
          }
        }),
        headers: {
          "Content-type": "application/json",
          mcdotoken: "ExMzEyMzMudTk4VTk4V0Y3NkZjEyRlNEujEydWF3bjgxMnVWVleyJzd94V0Y3NkZjEy",
        },
      }
    );
    const json = await response.json();
    dispatch(setCalendarioAnterior(json));
    
  } catch (error) {
    console.log("Api layout : error");
    console.error(error);
    dispatch(setLoadingCalendarioAnterior(false))
  } finally {
    console.log("Calendario : ok");
    dispatch(setLoadingCalendarioAnterior(false))
  }
};


export const getDisponibilidadEcoVending = () => async (dispatch) => {
  try {

    dispatch(setLoadingIndicadores(true))
    const response = await fetch(
      `${API_URL}/sucursals/kpi/Disponibilidad`,
      {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          mcdotoken:
            "hdTk4VUFOOLhPSpAhP3Ub30BppmFPkjl9-8cNPPwzg7kkiOiJkYXMiJkYXMkilk8pAidYwg6v",
        },
      }
    );
    const json = await response.json();
    console.log(json)
    dispatch(setDisponibilidadEcoVendings(json.pos));
    dispatch(setKPI(json.kpi))
    
  } catch (error) {
    console.log("Api indicadores : error");
    console.error(error);
  } finally {
    console.log("Api indicadores : ok");
    dispatch(setLoadingIndicadores(false))
  }
};