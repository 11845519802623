import { Helmet } from 'react-helmet-async';
// @mui
import { useTheme, styled} from '@mui/material/styles';
import { Grid, Container, Typography, Box, Table, TableBody, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import moment from 'moment';
// redux
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCalendarioNuevo, getCalendarioNuevoAnterior } from '../store/slices/indicadores';

// components
import {Logo, LogoPolarycs} from '../components/logo'
// ----------------------------------------------------------------------

export default function MovsBodega() {

  const theme = useTheme();
  const dispatch = useDispatch();
  const [ time, setTime ] = useState(0);
  const [counter, setCounter] = useState(1);

  const [diaNumero, setDiaNumero] = useState(0)

  const [ dia, setDia ] = useState('')
  const [ diaSiguiente, setDiaSiguiente ] = useState('')

  const { loadingCalendario, loadingCalendarioAnterior, calendario, calendarioSemAnterior } = useSelector((state) => state.indicadores);

  useEffect(() => {
    // Calendario Anterior
    dispatch(getCalendarioNuevoAnterior(
      moment().subtract(5, 'days').format('MM-DD-YYYY')
    ));
    // Calendario Hoy
    dispatch(getCalendarioNuevo(
      moment().add(1, 'days').format('MM-DD-YYYY')
    ));

    console.log(
      'hoy : ', moment().add(0, 'days').format('DD-MM-YYYY'),
      ' numeroDia: ', moment().add(0, 'days').weekday(), 
      ' diaSiguiente1 : ',  moment().add(3, 'days').format('DD-MM-YYYY'),
      ' diaSiguiente2 : ', moment().add(1, 'days').format('DD-MM-YYYY')
    )
    
    if( moment().add(0, 'days').weekday() === 5 ) {
      setDiaSiguiente(moment().add(3, 'days').format('DD-MM-YYYY'))
      setDia(moment().add(0, 'days').format('DD-MM-YYYY'))
      setDiaNumero(moment().add(0, 'days').weekday())
    } 
    else if ( moment().add(0, 'days').weekday() === 6 || moment().add(0, 'days').weekday() === 0 || moment().add(0, 'days').weekday() === 1 ) {

      if ( moment().add(0, 'days').weekday() === 6 ) {
        setDia(moment().add(2, 'days').format('DD-MM-YYYY'))
        setDiaSiguiente(moment().add(3, 'days').format('DD-MM-YYYY'))
      } else if ( moment().add(0, 'days').weekday() === 0)  {
        setDia(moment().add(1, 'days').format('DD-MM-YYYY'))
        setDiaSiguiente(moment().add(2, 'days').format('DD-MM-YYYY'))
      } else {
        console.log('por aqui ! :', moment().add(0, 'days').format('DD-MM-YYYY'), moment().add(1, 'days').format('DD-MM-YYYY'))
        setDia(moment().add(0, 'days').format('DD-MM-YYYY'))
        setDiaSiguiente(moment().add(1, 'days').format('DD-MM-YYYY'))
      }
      setDiaNumero(1)
    } 
    else {
      setDiaSiguiente(moment().add(1, 'days').format('DD-MM-YYYY'))
      setDia(moment().add(0, 'days').format('DD-MM-YYYY'))
      setDiaNumero(moment().add(0, 'days').weekday())
    }
    //setDiaSiguiente('04-10-2024')

  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCounter((prevCounter) => prevCounter + 1);
      window.location.reload(false);
    }, 30000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  function createData(
    name,
    calories,
    fat,
    carbs,
    protein,
  ) {
    return { name, calories, fat, carbs, protein };
  }

  const TagDespacho = styled('div')(() => ({
    borderRadius: '100px',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.ecocargaDetergente.lighter,
    paddingTop: 5, 
    paddingBottom: 5,
    paddingLeft: 15,
    paddingRight:15,
    marginRight: 15,
    display: 'inline-block'
  }));

  const TagDespachoCantidad = styled('div')(({ theme }) => ({
    borderRadius: '100px',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.ecocargaLavalozas.lighter,
    paddingTop: 5, 
    paddingBottom: 5,
    paddingLeft: 15,
    paddingRight:15,
    marginRight: -15,
    marginTop:-10,
    marginBottom:-10,
    marginLeft:5,
    color: theme.palette.ecocargaLavalozas.darken, 
    fontWeight: 'bold',
    display: 'inline-block',
    borderTopLeftRadius:0,
    borderBottomLeftRadius:0
  }));

  const BoxLayout = styled('div')(({ theme }) => ({
    margin: 'auto',
    borderRadius: '5px',

    width:'100%',
    justifyContent: 'center',
    marginBottom: theme.spacing(3),
    backgroundColor: '#e8f4fc',
    padding: 20
  }));

  const BoxLayoutContainer = styled('div')(({ theme }) => ({
    borderRadius: '5px',

    width:'100%',
    justifyContent: 'center',
    marginBottom: theme.spacing(2),
    padding: 20,
    display: 'grid',
    gridTemplateColumns:'25% 25% 25% 25%',
    gridGap: 20,
    gridAutoFlow: 'column',
    textAlign: 'center',
    gridAutoRows: '100px'

  }));

  const TarjetaDespacho = styled('div')(({ theme }) => ({
    borderRadius: '5px',
    width:'100%',
    justifyContent: 'center',
    marginBottom: theme.spacing(2),
    backgroundColor: '#ffffff',
    padding: 10,
    boxShadow: '0px 8px 20px 0px #dbdbdb',
    border : '2px solid #646464'
  }));
  
  const BoxDespacho = styled('div')(({ theme }) => ({
    borderRadius: '0px',
    width:'100%',
    justifyContent: 'center',
    marginBottom: theme.spacing(2),
    backgroundColor: '#ffffff',
    padding: 20,
    borderLeft : '4px solid #646464'
  }));

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#acd8ff",
      color: "#00315e",
      fontSize: 20,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 18,
    },
  }));

  const BotonEstado = styled('div')(({ theme }) => ({
    borderRadius: '100%',
    width:'20px',
    height: '20px',
    backgroundColor: 'red',
    marginRight: '20px',
    position: 'absolute',
    display: 'inline-flex',
    top: '5px',
    left: 0

  }));

  function getDayCL(day) {
    if (day === 1) return 'Lunes';
    if (day === 2) return 'Martes';
    if (day === 3) return 'Miércoles';
    if (day === 4) return 'Jueves';
    if (day === 5) return 'Viernes';
    if (day === 6) return 'Sábado';
    if (day === 7) return 'Domingo';
    return 'x'
  }
  
  function renderTarjeta(data) {
    return(
      <>
      <BoxDespacho>
        <Typography variant="h6" sx={{fontWeight: 'bold'}}>
          RUTA / {data.ruta}
        </Typography>
        <Typography variant="h6" sx={{fontWeight: 'bold', marginBottom:2}}>
          CAMION / {data.camion}
        </Typography>

        {(data.despacho.map(y => {
          return(
            <>
            <TarjetaDespacho>
              {(y.items.map( e => {
                return(
                  <Typography variant="h5" sx={{ mb: 1, position: 'relative', paddingLeft: '30px'}}>
                    <BotonEstado />
                    {e.cantidad} x {e.producto} 
                    
                  </Typography>
                )
              }))}
              <Typography variant="h6" sx={{fontWeight: 'normal'}}>
                {y.pos}
              </Typography>
            </TarjetaDespacho>
            </>
          )
        }))}
      </BoxDespacho>
        
      </>
    )
  }

  function renderCaja(data) {
    return(
      <>
        <BoxDespacho>
          <Typography variant="h6" sx={{fontWeight: 'bold'}}>
            {data}
          </Typography>
        </BoxDespacho>
      </>
    )
  }

  function renderCard(data, estado) {
    return (
      <div style={{ padding: 0, borderRadius: 5}}>
        {/* <span> */}
        {/* <o className={`patenteCamion`}>Transporte : {data.transportePatente}</o></span> */}
        <o className={`rutaCamion ruta${data.rutaId}`}>{data.rutaNombre} / {data.transportePatente}</o>
        {/* <o className={`rutaCamion ruta${data.rutaId}`}>{data.rutaNombre}</o> */}
        {(data.pos.map((item) => {

          
          return (
            <div className="mcdoItemDespacho">
              {(item.planificacion.map((material => {
                
                if( estado === 'pendientes' ) {
                  if( !material.despachoCompletado ) {
                    
                    return(
                      <div className="lineaPlanificacion"> 
                        {/* {this.renderStatus(material.estado)}  */}
                        <span style={{fontWeight:'bold'}}>
                          {material.cantidad - material.despachosTablet.length } x 
                          {material.productoNombreLimpio.replace(' ECOCARGA', '')}
                          </span> 
                      </div>
                    )
                  }
                }

                if( estado === 'procesados' && material.despachosTablet.length > 0) {
                  if( material.despachoEstado === 'procesado' ) {
                    return(
                      <div className="lineaPlanificacion"> 
                        {/* {this.renderStatus(material.estado)}  */}
                        <span style={{fontWeight:'bold'}}>
                          {material.despachosTablet.length } x 
                          {material.productoNombreLimpio.replace(' ECOCARGA', '')}
                          </span> 
                      </div>
                    )
                  }
                }
          

                if( estado === 'despachados' && material.despachosTablet.length > 0) {
                  if( material.despachoEstado === 'despachado' ) {
                    return(
                      <div className="lineaPlanificacion"> 
                        {/* {this.renderStatus(material.estado)}  */}
                        <span style={{fontWeight:'bold'}}>
                          {material.cantidad} x 
                          {material.productoNombreLimpio.replace(' ECOCARGA', '')}
                          </span> 
                      </div>
                    )
                  }
                }
               

              })))}          

              <div className='mcdoHeader'>
                <span>
                  <o className="pos posNombre gris">POS</o>
                  <b>{item.posNombre} </b>

                  {( estado === 'despachados' && item.pos_despachoEstado === 'despachado' ) ? (
                  <>
                    {( item.pos_despachoReferencias != {} ) ? (                      
                      <b>/ Doc: { item.pos_despachoReferencias.numero_documento }</b>                      
                    ):(<></>)}
                  </>  
                ):(<></>)}
                </span>
                
                
              </div>
                  
            </div>
          )
        }))}
      </div>
    )
  }


  if(!loadingCalendario && !loadingCalendarioAnterior) {
    return (
      <>
        <Helmet>
          <title>Bodega EcoCarga</title>
        </Helmet>

        <Container style={{maxWidth:'100%'}}>
          <Box sx={{ px: 1, py: 1, display: 'inline-flex', position: 'absolute', right: 50 }}>
            <Logo />
          </Box>
          <Box sx={{ px: 1, py: 1, display: 'inline-flex', position: 'absolute', bottom: 20, right:50 }}>
            <LogoPolarycs sx={{marginLeft:2}}/>
          </Box>
          <Typography variant="h3" sx={{ mb: -2 }} mt={-13}>
            Mercado Circular / EcoCarga / Pedidos del día {dia.split('-')[0]} / {moment().format('DD-MM-YYYY HH:mm:ss')}
          </Typography>

          <BoxLayoutContainer item xs={12} sm={6} md={6} sx={{textAlign:'left'}}>

            <BoxLayout 
              item 
              xs={4} 
              sm={4} 
              md={4} 
              sx={{
                textAlign:'left', 
                borderColor: '#2ecc71', 
                borderWidth: 5, 
                // borderStyle:'solid',
                backgroundColor:'#fff',
                padding: 0
              }}
            >
              
              <Typography variant="h5" sx={{ mb: 2, textAlign: 'center', backgroundColor:'#d2e0fb', padding: '5px', borderRadius: '5px', color: '#1769ff', marginBottom:'10px'}}>
                Despachados Hoy {dia.split('-')[0]}

              </Typography>   

              {diaNumero === 1 && calendarioSemAnterior?.semanaPlanificacion  && (
                  <>
                    {calendarioSemAnterior?.semanaPlanificacion.map( (item, index) => {
                      if( item.planificacionRutas.length > 0 && item.fecha.split('T')[0].split('-')[2] === dia.split('-')[0]) {
                        return(
                          <>
                            {item.planificacionRutas.map((rutas) => {
                              return (renderCard(rutas, 'despachados'))
                            })}
                          </>

                        )
                      }
                    })}
                  </>
              )}
              {diaNumero !== 1 && calendario?.semanaPlanificacion  && (
                  <>
                    {calendario?.semanaPlanificacion.map( (item, index) => {
                      if( item.planificacionRutas.length > 0 && item.fecha.split('T')[0].split('-')[2] === dia.split('-')[0]) {
                        return(
                          <>
                            {item.planificacionRutas.map((rutas) => {
                              return (renderCard(rutas, 'despachados'))
                            })}
                          </>

                        )
                      }
                    })}
                  </>
              )}        

            </BoxLayout>

            <BoxLayout 
              item xs={4} sm={4} md={4} 
              sx={{
                textAlign:'left', 
                // borderColor: '#e74c3c', 
                // borderWidth: 5, 
                // borderStyle:'solid',
                backgroundColor:'#fff',
                padding: 0
              }}
            >
              
              <Typography variant="h5" sx={{ mb: 2, textAlign: 'center', backgroundColor:'#ffcfca', padding: '5px', borderRadius: '5px', color: '#ae3629', marginBottom:'10px'}}>
                Pendientes {diaNumero === 5 ? 'Lunes' : 'Mañana'} {diaSiguiente.split('-')[0]}
              </Typography>

              {
                calendario.semanaPlanificacion ? (
                  <>
                    {calendario.semanaPlanificacion.map( (item, index) => {
                      if( item.planificacionRutas.length > 0 && item.fecha.split('T')[0].split('-')[2] === diaSiguiente.split('-')[0]) {
                        return(
                          <>
                            {item.planificacionRutas.map((rutas) => {
                              return (renderCard(rutas, 'pendientes'))
                            })}
                          </>

                        )
                      }
                    })}
                  </>
                ):(<></>)
              }

            </BoxLayout>

            <BoxLayout 
              item xs={4} sm={4} md={4} 
              sx={{
                textAlign:'left', 
                // borderColor: '#f1c40f', 
                // borderWidth: 5, 
                // borderStyle:'solid',
                backgroundColor:'#fff',
                padding: 0
              }}
            >
              
              <Typography variant="h5" sx={{ mb: 2, textAlign: 'center', backgroundColor:'#fff7d6', padding: '5px', borderRadius: '5px', color: '#f1c40f', marginBottom:'10px'}}>
                Procesados {diaNumero === 5 ? 'Lunes' : 'Mañana'} {diaSiguiente.split('-')[0]}
              </Typography>

              {
                calendario.semanaPlanificacion ? (
                  <>
                    {calendario.semanaPlanificacion.map( (item, index) => {
                      if( item.planificacionRutas.length > 0 && item.fecha.split('T')[0].split('-')[2] === diaSiguiente.split('-')[0]) {
                        return(
                          <>
                            {item.planificacionRutas.map((rutas) => {
                              return (renderCard(rutas, 'procesados'))
                            })}
                          </>

                        )
                      }
                    })}
                  </>
                ):(<></>)
              }

            </BoxLayout>

            <BoxLayout 
              item xs={4} sm={4} md={4} 
              sx={{
                textAlign:'left', 
                // borderColor: '#2ecc71', 
                // borderWidth: 5, 
                // borderStyle:'solid',
                backgroundColor:'#fff',
                padding: 0
              }}
            >
              
              <Typography variant="h5" sx={{ mb: 2, textAlign: 'center', backgroundColor:'#c0f9d8', padding: '5px', borderRadius: '5px', color: '#2ecc71', marginBottom:'10px'}}>
                Despachados {diaNumero === 5 ? 'Lunes' : 'Mañana'} {diaSiguiente.split('-')[0]}
              </Typography>

              {
                calendario.semanaPlanificacion ? (
                  <>
                    {calendario.semanaPlanificacion.map( (item, index) => {
                      if( item.planificacionRutas.length > 0 && item.fecha.split('T')[0].split('-')[2] === diaSiguiente.split('-')[0]) {
                        return(
                          <>
                            {item.planificacionRutas.map((rutas) => {
                              return (renderCard(rutas, 'despachados'))
                            })}
                          </>
                        )
                      }
                    })}
                  </>
                ):(<></>)
              }

            </BoxLayout>


          </BoxLayoutContainer>
          

        </Container>
      </>
    );
  }

  return (<>
  
    <Container style={{maxWidth:'100%'}}>
          <Box sx={{display: 'block', textAlign:'center'}}>
            <Logo />
          </Box>
          <Box sx={{display: 'block', textAlign:'center'}}>
            <Typography variant="h3" sx={{ mb: 2 }} mt={2}>
              Refrescando Picking...
            </Typography>
          </Box>
    </Container>
  
  </>)
}
